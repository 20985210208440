import * as React from "react"

import Layout from "components/layout"
import Seo from "components/seo"
import { Flex } from "theme-ui"
import { StaticImage } from "gatsby-plugin-image"
import { SectionHeader, SectionParagraph } from "components/various"
import TextLoop from "components/TextLoop"

const quotes = [
  "الحياة تشبه الدراجة ، عليك المضي قدمًا حتى لا تفقد توازنك",
  "درء المفسدة خير من جلب المنفعة",
  "وإذا قصدت لحاجةٍ فاقصد لمعترفٍ بقدرك",
  "عامل الناس كما تحب أن يعاملوك",
  "الوقت كسيف إن لم تقطعه قطعك",
  "كلما زادت المعرفة قلت الحاجة للكلام",
]

const AboutPage = () => (
  <Layout>
    <Seo title="About" />
    <StaticImage
      src="../images/about.jpg"
      loading="eager"
      quality={95}
      formats={["auto", "webp", "avif"]}
      sx={{ width: "full", height: [200, 300] }}
    />
    <Flex
      sx={{
        flexDirection: "column",
        alignItems: "center",
        my: 5,
        px: [2, 3, 3, 6, 7],
        justifyContent: "space-around",
      }}
    >
      <SectionHeader>Historique</SectionHeader>
      <SectionParagraph>
        Advisory & Certifying in Business (ACB) est une société d'expertise
        comptable inscrite au tableau de l'Ordre des Experts Comptables de
        Tunisie (OECT). Elle a été fondée par Messieurs Mohamed MIGAOU, Hilal
        OUALI & Oussema BAHLOUL Experts Comptables membres de l'OECT
        respectivement depuis 2006, 2008 et 2018.
      </SectionParagraph>
      <SectionParagraph>
        Historiquement, les deux associés Messieurs Hilal OUALI & Mohamed MIGAOU
        qui ont une experience moyenne de plus de 20 ans ont fondé en 2008 la
        société d'expertise comptable Audit & consulting Business ayant conduit
        une centaine de missions dans les domaines d'audit, de consulting et de
        formation dans divers secteurs d'activités.
      </SectionParagraph>
      <SectionParagraph>
        La structure permanente, la compétence technique et l'expérience
        professionnelle des associés et du personnel d'intervention permettent à
        ACB d'offrir aux entreprises des services de qualité, notamment dans les
        domaines d'activité suivants:
      </SectionParagraph>
      <ul sx={{ pl: 4 }}>
        <li>Audit </li>
        <li>Due diligences & services de certification</li>
        <li>Assistance juridique, fiscale et sociale </li>
        <li> Externalisation des fonctions comptables et financières </li>
        <li> Corporate finance & business development </li>
        <li>
          Diagnostic, digitalisation, organisation et amélioration des process
          de gestion et de pilotage
        </li>
      </ul>
      <SectionParagraph>
        Notre clientèle, diversifiée, comprend aussi bien des entreprises
        tunisiennes que des filiales de grands groupes multinationaux opérant
        dans les différents secteurs économiques (Télécom, énergie, transport,
        services, industrie manufacturière, hôtellerie, etc.).
      </SectionParagraph>
      <SectionParagraph>
        La qualité et l'excellence dans la conduite des travaux d'audit et de
        conseil nous ont permis d'atteindre une parfaite image dans le paysage
        économique national et une appréciation favorable par la communauté des
        affaires.
      </SectionParagraph>
    </Flex>
    <TextLoop texts={quotes} scrollDirection="right" />
    {/* <div className={styles.textCenter}>
      <StaticImage
        src="../images/example.png"
        loading="eager"
        width={64}
        quality={95}
        formats={["auto", "webp", "avif"]}
        alt=""
        style={{ marginBottom: `var(--space-3)` }}
      />
      <h1>
        Welcome to <b>Gatsby!</b>
      </h1>
      <p className={styles.intro}>
        <b>Example pages:</b>
        {samplePageLinks.map((link, i) => (
          <React.Fragment key={link.url}>
            <Link to={link.url}>{link.text}</Link>
            {i !== samplePageLinks.length - 1 && <> · </>}
          </React.Fragment>
        ))}
        <br />
        Edit <code>src/pages/index.js</code> to update this page.
      </p>
    </div> */}
  </Layout>
)

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => <Seo title="About" />

export default AboutPage
